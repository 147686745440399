import { IMetaHead } from "@/interfaces";
import Head from "next/head";
type props = {
    metaData: IMetaHead;
}
export function MetaHead({ metaData }: props) {
    return (
        <Head>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=3.0" />
            <title>{metaData.title}</title>
            <meta name="description" content={metaData.description} />
        </Head>
    )
}