import { HomePage } from "@/components/homePage/HomePage";
import { MetaHead } from "@/components/shared/MetaHead";
import { IHomeData, IMetaHead } from "@/interfaces";
import cmsService from "@/libs/services/cmsService";

type props = {
  HomeData: IHomeData
}
const index = ({ HomeData }: props) => {
  const metaData: IMetaHead = {
    title: HomeData.homeData.seo_title || "",
    description: HomeData.homeData.seo_description || ""
  }
  return (
    <>
      <MetaHead metaData={metaData} />
      <HomePage HomeData={HomeData} />
    </>
  )
}

export async function getServerSideProps(context: any) {
  const categories = await cmsService.getAllCategories()
  const doctorsList = await cmsService.getAllDoctors()
  const homeData = await cmsService.getHomeData()
  const patientStories = await cmsService.getPatientStories()
  if (!categories || categories.length === 0 || !doctorsList || doctorsList.length === 0 || !homeData || !homeData.length || !patientStories || !patientStories.length)
    return { notFound: true }
  return {
    props: {
      HomeData: {
        categories: categories,
        doctorsList: doctorsList,
        homeData: homeData[0],
        patientStories: patientStories
      }
    },
  };
};
export default index;